<template>
  <div
      class="flex flex-col text-a-neutral relative font-sans tracking-wide"
  >
    <FundraisingNavMobile class="flex md:hidden" />
    <Transition name="fade">
      <MainSideMenuMobile class="flex md:hidden"/>
    </Transition>
    <FundraisingNav class="hidden md:flex" />
    <div class="flex flex-col">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
</script>